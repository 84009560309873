import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Header } from '../../components/Header/Header';
import { FirstStep } from '../../components/StepsComponents/FirstStep';
import { ThirdStep } from '../../components/StepsComponents/ThirdStep';
import { FourthStep } from '../../components/StepsComponents/FourthStep';
import {
    fillFormAction,
    formsReducer,
    validateForm,
} from '../../helpers/formUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveQuote } from '../../helpers/AdditionalFunctions/saveQuote';
import { useDispatch, useSelector } from 'react-redux';
import './NewQuotPage.scss';
import {
    cancelQuote,
    completeQuote,
    getTableData,
    helpTicket,
    updateQuote,
    validationRules,
} from '../../crud/quote';
import {
    setCurrentQuote,
    setQuotePackage,
    setViewQuoteId,
} from '../../redux/quote/quote';
import { Loader } from '../../components/Loader/Loader';
import { SecondStepRental } from '../../components/StepsComponents/SecondStepRental';
import { SecondStepStudent } from '../../components/StepsComponents/SecondStepStudent';
import { fillForm } from '../../helpers/AdditionalFunctions/fillForm';
import { disabledFields } from '../../helpers/AdditionalFunctions/disabledFields';
import { validateStudent } from '../../helpers/AdditionalFunctions/validate/validateStudent';
import { AreYouSureModal } from '../../components/AreYouSureModal/AreYouSureModal';
import { validateWithConstStudent } from '../../helpers/AdditionalFunctions/validate/validateWithConstStudent';
import { validateRental } from '../../helpers/AdditionalFunctions/validate/validateRental';
import useBlocker from '../../helpers/useBlocker';
import { setIsChanged } from '../../redux/quote/quote';
import { validateWithConstRental } from '../../helpers/AdditionalFunctions/validate/validateWithConstRental';
import { ContactUsModal } from '../../components/ContactUsModal/ContactUsModal';
import {
    setIsPopup,
    setIsPopupCompleteQuote,
    setIsPopupErr,
    setIsPopupSend,
} from '../../redux/popup/popup';

const initStateFirst = {
    agree_risk: false,
    isFormValid: false,
};
const initStateSecondResidental = {
    package: 'Residential Rental',
    rental_type: { value: '', disabled: false },
    deductible: { value: '', disabled: false },

    landlord_contents: { value: '', disabled: false },
    gross_rental_income: { value: '', disabled: false },
    liability_limit: { value: '', disabled: false },

    improvements_betterments: { value: '', disabled: true },
    loss_assessment: { value: '', disabled: true },
    unit_owner_protection: { value: '', disabled: true },

    additional_building: { value: '', disabled: true },
    building: { value: '', disabled: true },

    flood: { value: '', disabled: false },
};
const initStateSecondStudent = {
    package: 'Student Rental',
    number_of_students: { value: '', disabled: false },
    sewer_back_up: { value: '', disabled: true },
    rental_type: { value: '', disabled: false },
    deductible: { value: '', disabled: false },
    landlord_contents: { value: '', disabled: false, hasError: '' },
    gross_rental_income: { value: '', disabled: false },
    liability_limit: { value: '', disabled: false },

    improvements_betterments: { value: '', disabled: true, hasError: '' },
    loss_assessment: { value: '', disabled: true },
    unit_owner_protection: { value: '', disabled: true },

    additional_building: { value: '', disabled: true },
    dwelling: { value: '', disabled: true },

    location_three_or_more_years: { value: false, disabled: true },
    building_past_three_years: { value: false, disabled: true },
    dwelling_age: { value: false, disabled: true },
    certified_rental_building: { value: false, disabled: true },
    customer_more_three_year: { value: false, disabled: true },

    flood: { value: '', disabled: false },
    other_policy_with_halwell: { value: '', disabled: true },
    isFormValid: false,
};
const initStateThird = {
    name_of_insured: { value: '', hasError: '' },
    mailing_address: { value: '', hasError: '' },
    location_address: { value: '', hasError: '' },
    mortgagee: { value: '', hasError: '' },
    mortgagee_address: { value: '', hasError: '' },
    effective_date: { value: '', hasError: '' },
    policy_term: { value: '', hasError: '' },
    prior_insurance: { value: '', hasError: '' },
    broker_location: { value: '', hasError: '' },

    has_insured_declined: { value: false },
    insured_more_tree_years: { value: false },
    within_100_km: { value: false },
    required_proof: { value: false },

    contained_units: { value: null, hasError: '' },
    responsible_dwelling: { value: '', hasError: '' },
    responsible_for_snow: { value: '', hasError: '' },
    how_often_inspected: { value: '', hasError: '' },

    two_or_more_checks: { value: false },
    is_separate_agreement: { value: false },

    date: { value: '', hasError: '' }, // not mandatory
    description: { value: '', hasError: '' }, // not mandatory
    amount_paid: { value: '', hasError: '' }, // not mandatory
    is_open: { value: '', hasError: '' }, // not mandatory
    isFormValid: false,
};

const initStateFourth = {
    additional_notes: '',
};

export const NewQuotPage = ({ clearData }) => {
    const currentQuote = useSelector((state) => state.quote.currentQuote);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [formData1, setFormData1] = useReducer(formsReducer, initStateFirst);
    const [formData2Residental, setFormData2Residental] = useReducer(
        formsReducer,
        initStateSecondResidental
    );
    const [formData2Student, setFormData2Student] = useReducer(
        formsReducer,
        initStateSecondStudent
    );
    const [additionalCoverages, setAdditionalCoverages] = useState([]);
    const [formData3, setFormData3] = useReducer(formsReducer, initStateThird);
    const [formData4, setFormData4] = useReducer(formsReducer, initStateFourth);
    const [listOfLooses, setListOfLooses] = useState([
        {
            description: { value: '', error: '', hasError: false },
            date: { value: '', error: '', hasError: false },
            amount_paid: { value: '', error: '', hasError: false },
            is_open: { value: '', error: '', hasError: false },
            localId: Math.random() + '',
        },
    ]);

    const [isFirstTime, setIsFirstTime] = useState(true);
    const viewQuote = useSelector((state) => state.quote.viewQuoteId);
    const quotePackage = useSelector((state) => state.quote.quotePackage);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState();
    const [errorOnFirstStep, setErrorOnFirstStep] = useState(false);
    const [totalPremium, setTotalPremium] = useState('00,000');
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [openModalSave, setOpenModalSave] = useState(false);
    const [addCovError, setAddCovError] = useState('');
    const [pastSteps, setPastSteps] = useState(1);
    const location = useLocation();
    const [neededPage, setNeededPage] = useState('');
    const [fillFormFinished, setFillFormFinished] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [checkCrime, setCheckCrime] = useState(false);
    const isChanged = useSelector((state) => state.quote.isChanged);
    const [openHelpModal, setOpenHelpModal] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [afterSave, setAfterSave] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);


    useEffect(() => {
        if (location.pathname.includes('/new_quote')) {
            if (!location.search.includes('step=')) {
                navigate(`/new_quote/${currentQuote}?step=1`);
            }
        }
    }, [location]);

    const beforeUnLoad = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = '';
    };

    useEffect(() => {
        if (isChanged) {
            window.addEventListener('beforeunload', beforeUnLoad);
        }
        return () => {
            window.removeEventListener('beforeunload', beforeUnLoad);
        };
    }, [isChanged]);

    useEffect(() => {
        if (!formData2Student.other_policy_with_halwell.value) {
            setFormData2Student(
                fillFormAction({
                    ...formData2Student,
                    other_policy_with_halwell: {
                        ...formData2Student.other_policy_with_halwell,
                        error: '',
                        hasError: false,
                    },
                })
            );
        }
    }, [formData2Student.other_policy_with_halwell.value]);

    useEffect(() => {
        if (clearData) {
            setFormData1(
                fillFormAction({
                    agree_risk: false,
                    location_occupied: false,
                    more_than_2_mortgages: false,
                    mortgages_through_traditional_lenders: false,
                    isFormValid: false,
                })
            );
            setFormData2Residental(
                fillFormAction({
                    package: 'Residential Rental',
                    rental_type: { value: '', disabled: false },
                    deductible: { value: '', hasError: '', disabled: false },

                    landlord_contents: { value: '', disabled: false },
                    gross_rental_income: { value: '', disabled: false },
                    liability_limit: { value: '', disabled: false },

                    improvements_betterments: { value: '', disabled: true },
                    loss_assessment: { value: '', disabled: true },
                    unit_owner_protection: { value: '', disabled: true },

                    additional_building: { value: '', disabled: true },
                    building: { value: '', disabled: true },

                    flood: { value: '', disabled: false },
                    isFormValid: false,
                })
            );
            setFormData2Student(
                fillFormAction({
                    number_of_students: { value: '', disabled: false },
                    sewer_back_up: { value: '', disabled: true },
                    rental_type: { value: '', disabled: false },
                    deductible: { value: '', disabled: false },
                    landlord_contents: {
                        value: '',
                        disabled: false,
                        hasError: '',
                    },
                    gross_rental_income: { value: '', disabled: false },
                    liability_limit: { value: '', disabled: false },
                    improvements_betterments: {
                        value: '',
                        disabled: true,
                        hasError: '',
                    },
                    loss_assessment: { value: '', disabled: true },
                    unit_owner_protection: { value: '', disabled: true },
                    additional_building: { value: '', disabled: true },
                    dwelling: { value: '', disabled: true },

                    location_three_or_more_years: {
                        value: false,
                        disabled: true,
                    },
                    building_past_three_years: {
                        value: false,
                        disabled: true,
                    },
                    dwelling_age: { value: false, disabled: true },
                    certified_rental_building: {
                        value: false,
                        disabled: true,
                    },
                    customer_more_three_year: {
                        value: false,
                        disabled: true,
                    },

                    flood: { value: '', disabled: false },
                    other_policy_with_halwell: {
                        value: '',
                        disabled: true,
                    },
                    isFormValid: false,
                })
            );
            setFormData3(
                fillFormAction({
                    name_of_insured: { value: '', hasError: '' },
                    mailing_address: { value: '', hasError: '' },
                    location_address: { value: '', hasError: '' },
                    mortgagee: { value: '', hasError: '' },
                    mortgagee_address: { value: '', hasError: '' },
                    effective_date: { value: '', hasError: '' },
                    policy_term: { value: '', hasError: '' },
                    prior_insurance: { value: '', hasError: '' },
                    broker_location: { value: '', hasError: '' },

                    has_insured_declined: { value: false },
                    insured_more_tree_years: { value: false },
                    within_100_km: { value: false },
                    required_proof: { value: false },

                    contained_units: { value: null, hasError: '' },
                    responsible_dwelling: { value: '', hasError: '' },
                    responsible_for_snow: { value: '', hasError: '' },
                    how_often_inspected: { value: '', hasError: '' },

                    two_or_more_checks: { value: false },
                    is_separate_agreement: { value: false },

                    date: { value: '', hasError: '' }, // not mandatory
                    description: { value: '', hasError: '' }, // not mandatory
                    amount_paid: { value: '', hasError: '' }, // not mandatory
                    is_open: { value: '', hasError: '' }, // not mandatory
                    isFormValid: false,
                })
            );
        }
    }, [clearData]);

    useEffect(() => {
        setStep(+location.search.split('=')[1]);
    }, [location]);

    useEffect(() => {
        disabledFields(
            formData2Student,
            setFormData2Student,
            formData2Residental,
            setFormData2Residental,
            fillFormAction,
            quotePackage
        );
    }, [
        formData2Student.number_of_students,
        formData2Student.rental_type,
        formData2Residental.rental_type,
    ]);

    useEffect(() => {
        if (formData1.agree_risk) {
            setErrorOnFirstStep(false);
        }
    }, [formData1.agree_risk]);

    useEffect(() => {
        validationRules().then((res) => setValidation(res.data));
    }, []);

    useEffect(() => {
        if (viewQuote) {
            fillForm(
                viewQuote,
                setLoading,
                dispatch,
                setListOfLooses,
                setQuotePackage,
                fillFormAction,
                setFormData2Student,
                setFormData2Residental,
                setFormData3,
                setFormData1,
                setAdditionalCoverages,
                setPastSteps,
                setFillFormFinished,
                setFormData4,
                setCheckCrime,
                navigate
            );
            disabledFields(
                formData2Student,
                setFormData2Student,
                formData2Residental,
                setFormData2Residental,
                fillFormAction,
                quotePackage
            );
        }
    }, [viewQuote]);

    const completeQuoteFunc = () => {
        const save = false;
        saveQuote(
            step,
            isFirstTime,
            listOfLooses,
            currentQuote,
            quotePackage,
            setListOfLooses,
            formData3,
            formData2Student,
            setFormData2Student,
            formData2Residental,
            setFormData2Residental,
            formData1,
            setIsFirstTime,
            dispatch,
            viewQuote,
            setLoading,
            additionalCoverages,
            openModalSave,
            setOpenModalSave,
            navigate,
            save,
            pastSteps,
            formData4,
            setTableData,
            totalPremium
        );
        completeQuote(currentQuote).then(() => {
            dispatch(setIsPopupCompleteQuote(true));
            setTimeout(() => {
                dispatch(setIsPopupCompleteQuote(false));
            }, 3000);
            navigate('/quotes_in_work');
        });
    };

    const steps = [
        {
            id: 1,
            title: 'Step 1 - Eligibility Questions',
            status: step === 1 ? 'active' : step > 1 ? 'done' : '',
        },
        {
            id: 2,
            title: 'Step 2 - Rating Questions',
            status:
                step === 2
                    ? 'active'
                    : pastSteps > 2 && step > 2
                    ? 'done'
                    : step < 2 && pastSteps < 2
                    ? 'inactively'
                    : step < 2 && pastSteps > 2
                    ? 'past'
                    : 'past',
        },
        {
            id: 3,
            title: 'Step 3 - Client Information',
            status:
                step === 3
                    ? 'active'
                    : pastSteps > 3 && step > 3
                    ? 'done'
                    : step < 3 && pastSteps < 3
                    ? 'inactively'
                    : step < 3 && pastSteps > 3
                    ? 'past'
                    : 'past',
        },
        {
            id: 4,
            title: 'Step 4 - Overview and Complete',
            status:
                step === 4
                    ? 'active'
                    : pastSteps === 4
                    ? 'past'
                    : step < 4
                    ? 'inactively'
                    : '',
        },
    ];

    const validateFormFunc = () => {
        let isValid = true;
        if (step === 1) {
            if (!formData1.agree_risk) {
                isValid = false;
                setErrorOnFirstStep(true);
            }
            if (formData1.agree_risk) {
                isValid = true;
                setErrorOnFirstStep(false);
            }
        }
        if (step === 2 && quotePackage.title === 'Residential Rental') {
            if (isValid) {
                isValid = validateRental(
                    step,
                    formData2Residental,
                    setFormData2Residental,
                    fillFormAction,
                    additionalCoverages
                );
            }
            if (isValid) {
                for (let prop in formData2Residental) {
                    if (formData2Residental[prop].hasError) {
                        isValid = false;
                    }
                }
            }
            if (addCovError) {
                isValid = false;
            }
        }
        if (step === 2 && quotePackage.title === 'Student Rental') {
            if (isValid) {
                isValid = validateStudent(
                    step,
                    formData2Student,
                    setFormData2Student,
                    formData3,
                    setFormData3,
                    fillFormAction,
                    additionalCoverages
                );
            }
            if (isValid) {
                for (let prop in formData2Student) {
                    if (formData2Student[prop].hasError) {
                        isValid = false;
                    }
                }
            }

            if (addCovError) {
                isValid = false;
            }
        }
        if (step === 3) {
            isValid = validateStudent(
                step,
                formData2Student,
                setFormData2Student,
                formData3,
                setFormData3,
                fillFormAction,
                additionalCoverages
            );

            const data = [...listOfLooses];
            data.forEach((elem, index) => {
                if (
                    elem.date.value ||
                    elem.description.value ||
                    elem.amount_paid.value ||
                    elem.is_open.value.title
                ) {
                    if (!elem.date.value) {
                        isValid = false;
                        data[index].date.hasError = true;
                        data[index].date.error = 'This field is required';
                    }
                    if (!elem.description.value) {
                        isValid = false;
                        data[index].description.hasError = true;
                        data[index].description.error =
                            'This field is required';
                    }
                    if (!elem.amount_paid.value) {
                        isValid = false;
                        data[index].amount_paid.hasError = true;
                        data[index].amount_paid.error =
                            'This field is required';
                    }
                    if (!elem.is_open.value.title) {
                        isValid = false;
                        data[index].is_open.hasError = true;
                        data[index].is_open.error = 'This field is required';
                    }
                }
            });

            setListOfLooses(data);
        }
        return isValid;
    };

    const nextStep = () => {
        let isValid = validateFormFunc();
        setAfterSave(true);

        if (isValid) {
            let save = false;
            saveQuote(
                step,
                isFirstTime,
                listOfLooses,
                currentQuote,
                quotePackage,
                setListOfLooses,
                formData3,
                formData2Student,
                setFormData2Student,
                formData2Residental,
                setFormData2Residental,
                formData1,
                setIsFirstTime,
                dispatch,
                viewQuote,
                setLoading,
                additionalCoverages,
                openModalSave,
                setOpenModalSave,
                navigate,
                save,
                pastSteps,
                formData4,
                setTableData,
                totalPremium
            );

            navigate(`/new_quote/${currentQuote}?step=${step + 1}`);
        }
    };

    useEffect(() => {
        if (pastSteps < step && currentQuote) {
            setPastSteps(step);
            updateQuote(currentQuote, { step: step });
        }
    }, [step]);

    const prevStep = () => {
        if (isChanged) {
            setOpenModalSave(true);
            neededPage(step - 1);
        } else {
            dispatch(setIsChanged(false));
            navigate(`/new_quote/${currentQuote}?step=${step - 1}`);
        }
    };

    const lineWidth = () => {
        if (pastSteps === 1) {
            return { width: '60px' };
        }
        if (pastSteps === 2) {
            return { width: '278px' };
        }
        if (pastSteps === 3) {
            return { width: '500px' };
        }
        if (pastSteps === 4) {
            return { width: '735px' };
        }
    };
    const cancelQuoteFunc = () => {
        setLoading(true);
        cancelQuote(currentQuote)
            .then(() => {
                setOpenModalCancel(false);
                navigate('/cancelled_quotes');
            })
            .finally(() => setLoading(false));
    };

    const ClickOnRoadMap = () => {
        setOpenModalSave(false);
        fillForm(
            viewQuote,
            setLoading,
            dispatch,
            setListOfLooses,
            setQuotePackage,
            fillFormAction,
            setFormData2Student,
            setFormData2Residental,
            setFormData3,
            setFormData1,
            setAdditionalCoverages,
            setPastSteps,
            setFillFormFinished,
            setFormData4,
            setCheckCrime,
            navigate
        );
        navigate(`/new_quote/${currentQuote}?step=${neededPage}`);
    };

    useEffect(() => {
        if (description) {
            setDescriptionError('');
        }
    }, [description]);

    const submitHelp = () => {
        if (description) {
            const data = {
                quote_id: currentQuote,
                description: description,
            };
            setLoading(true);
            helpTicket(data)
                .then((res) => {
                    setOpenHelpModal(false);
                    dispatch(setIsPopupSend(true));
                    setTimeout(() => {
                        dispatch(setIsPopupSend(false));
                    }, 3000);
                })
                .catch((err) => {
                    dispatch(setIsPopupErr(true));
                    setTimeout(() => {
                        dispatch(setIsPopupErr(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        } else {
            setDescriptionError('Please add some notes');
        }
    };

    useEffect(() => {
        if (!openHelpModal) {
            setDescriptionError('');
            setDescription('');
        }
    }, [openHelpModal]);

    return (
        <>
            {openHelpModal && (
                <ContactUsModal
                    setOpenHelpModal={setOpenHelpModal}
                    submitHelp={submitHelp}
                    setDescription={setDescription}
                    description={description}
                    descriptionError={descriptionError}
                    currentQuote={currentQuote}
                    setLoading={setLoading}
                />
            )}
            {openModalCancel && (
                <AreYouSureModal
                    title={'Are you sure you want to cancel the quote?'}
                    cancel={() => setOpenModalCancel(false)}
                    submit={() => cancelQuoteFunc()}
                />
            )}
            {openConfirmModal && (
                <AreYouSureModal
                    title={'Completing the quote does not send it to HD Mutual'}
                    subtitle={
                        <div>
                            <b style={{ color: '#000000' }}>Warning:</b> When you 
                            complete your quote it is available in the next page. 
                            To send the quote for binding approval, you have to
                            click submit from that page. You will not be able to edit 
                            quote after it is completed. If you do not submit the quote 
                            to HD Mutual in 90 days, the quote will be cancelled
                            automatically.

                            All quotes are subject to underwriter approval.
                        </div>
                    }
                    cancel={() => setOpenConfirmModal(false)}
                    submit={() => completeQuoteFunc()}
                    buttonName="Complete"
                />
            )}
            {openModalSave && (
                <AreYouSureModal
                    title={
                        'You have unsaved changes. Are you sure you want to leave the page?'
                    }
                    cancel={() => setOpenModalSave(false)}
                    submit={() => {
                        ClickOnRoadMap();
                        dispatch(setIsChanged(false));
                    }}
                />
            )}
            <div className="new-quote">
                <Loader loading={loading} />
                <Header title={`New Quote - ${quotePackage.title}`} />
                <div className="new-quote__wrapper">
                    <div className="new-quote__steps steps">
                        <div className="steps__line">
                            <div
                                className="steps__active"
                                style={lineWidth()}
                            ></div>
                        </div>

                        {steps.map((elem) => {
                            return (
                                <div
                                    className="steps__step"
                                    key={elem.id}
                                    onClick={() => {
                                        if (pastSteps < elem.id) {
                                            return;
                                        } else {
                                            if (isChanged) {
                                                if (elem.id < step) {
                                                    setOpenModalSave(true);
                                                    setNeededPage(elem.id);
                                                }
                                                if (elem.id === step) {
                                                    return;
                                                }
                                                if (elem.id > step) {
                                                    setOpenModalSave(true);
                                                    setNeededPage(elem.id);
                                                }
                                            } else {
                                                navigate(
                                                    `/new_quote/${viewQuote}?step=${elem.id}`
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <div
                                        className={`steps__number ${elem.status}`}
                                    >
                                        {elem.status === 'done' ? '✓' : elem.id}
                                    </div>
                                    <div
                                        className={`steps__title ${elem.status}`}
                                    >
                                        {elem.title}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="new-quote__main main">
                        {step === 1 && (
                            <FirstStep
                                setFormData={setFormData1}
                                formData={formData1}
                                errorOnFirstStep={errorOnFirstStep}
                            />
                        )}
                        {step === 2 &&
                            quotePackage.title === 'Residential Rental' && (
                                <SecondStepRental
                                    formData={formData2Residental}
                                    setFormData={setFormData2Residental}
                                    totalPremium={totalPremium}
                                    setTotalPremium={setTotalPremium}
                                    validation={validation}
                                    additionalCoverages={additionalCoverages}
                                    setAdditionalCoverages={
                                        setAdditionalCoverages
                                    }
                                    setAddCovError={setAddCovError}
                                    addCovError={addCovError}
                                    step={step}
                                    fillFormFinished={fillFormFinished}
                                    checkCrime={checkCrime}
                                    setCheckCrime={setCheckCrime}
                                />
                            )}
                        {step === 2 &&
                            quotePackage.title === 'Student Rental' && (
                                <SecondStepStudent
                                    quotePackage={quotePackage}
                                    setFormData={setFormData2Student}
                                    formData={formData2Student}
                                    validation={validation}
                                    setTotalPremium={setTotalPremium}
                                    totalPremium={totalPremium}
                                    additionalCoverages={additionalCoverages}
                                    setAdditionalCoverages={
                                        setAdditionalCoverages
                                    }
                                    setAddCovError={setAddCovError}
                                    addCovError={addCovError}
                                    step={step}
                                    fillFormFinished={fillFormFinished}
                                />
                            )}
                        {step === 3 && (
                            <ThirdStep
                                setFormData={setFormData3}
                                formData={formData3}
                                setListOfLooses={setListOfLooses}
                                listOfLooses={listOfLooses}
                            />
                        )}
                        {step === 4 && (
                            <FourthStep
                                tableData={tableData}
                                setFormData4={setFormData4}
                                formData4={formData4}
                                loading={loading}
                                setLoading={setLoading}
                                currentQuote={currentQuote}
                                setTableData={setTableData}
                                afterSave={afterSave}
                            />
                        )}
                    </div>
                    <div className="new-quote__bottom-buttons">
                        {step === 1 ? (
                            ''
                        ) : (
                            <div
                                className="new-quote__save"
                                onClick={() => {
                                    setNeededPage(step - 1);
                                    prevStep();
                                }}
                            >
                                Previous
                            </div>
                        )}
                        <div
                            className="new-quote__save"
                            onClick={() => {
                                let isValid = true;

                                if (step < pastSteps) {
                                    isValid = validateFormFunc();
                                } else {
                                    if (
                                        quotePackage.title === 'Student Rental'
                                    ) {
                                        isValid = validateWithConstStudent(
                                            step,
                                            formData2Student,
                                            setFormData2Student,
                                            fillFormAction,
                                            validation
                                        );
                                    }
                                    if (
                                        quotePackage.title ===
                                        'Residential Rental'
                                    ) {
                                        isValid = validateWithConstRental(
                                            step,
                                            formData2Residental,
                                            setFormData2Residental,
                                            fillFormAction,
                                            validation
                                        );
                                    }

                                    if (addCovError) {
                                        isValid = false;
                                    }
                                }
                                if (isValid) {
                                    let save = true;
                                    saveQuote(
                                        step,
                                        isFirstTime,
                                        listOfLooses,
                                        currentQuote,
                                        quotePackage,
                                        setListOfLooses,
                                        formData3,
                                        formData2Student,
                                        setFormData2Student,
                                        formData2Residental,
                                        setFormData2Residental,
                                        formData1,
                                        setIsFirstTime,
                                        dispatch,
                                        viewQuote,
                                        setLoading,
                                        additionalCoverages,
                                        openModalSave,
                                        setOpenModalSave,
                                        navigate,
                                        save,
                                        pastSteps,
                                        formData4,
                                        setTableData,
                                        totalPremium
                                    );
                                }
                            }}
                        >
                            Save
                        </div>
                        {step === 4 ? (
                            <div
                                className="new-quote__next"
                                onClick={() => setOpenConfirmModal(true)}
                            >
                                Complete Quote
                            </div>
                        ) : (
                            <div
                                className="new-quote__next"
                                onClick={() => {
                                    nextStep();
                                }}
                            >
                                Save & Next
                            </div>
                        )}

<div
                            className={`btns__btn ${
                                !currentQuote ? 'disabled' : ''
                            }`}
                            onClick={() => {
                                if (currentQuote) {
                                    setOpenHelpModal(true);
                                }
                            }}
                        >
                            Ask an underwriter
                        </div>
                        <div
                            className={`btns__btn ${
                                !currentQuote ? 'disabled' : ''
                            }`}
                            onClick={() => {
                                return currentQuote
                                    ? setOpenModalCancel(true)
                                    : '';
                            }}
                        >
                            Cancel Quote
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
