import React, { useEffect, useState } from 'react';
import '../ContactUsModal/ContactUsModal.scss';
import Image from '../../assets/images/subject.png';
import { downloadPdf, downloadSecPdf, submitTicket } from '../../crud/quote';
import PDFImage from '../../assets/images/pdf.png';
import DownloadImage from '../../assets/images/Download.png';
import { useDispatch } from 'react-redux';
import {
    setIsPopupErr,
    setIsPopupSend,
    setIsPopupSubmit,
} from '../../redux/popup/popup';

export const SubmiteQuoteModal = ({
    setOpenHelpModal,
    setLoading,
    choosenId,
    setReload,
    subject,
}) => {
    const [description, setDescription] = useState('');
    const dispatch = useDispatch();
    const [descriptionError, setDescriptionError] = useState('');

    useEffect(() => {
        if (description) {
            setDescriptionError('');
        }
    }, [description]);

    const submitTicketFunc = () => {
        const data = {
            description: description.trim(),
        };
        setLoading(true);
        submitTicket(choosenId, data)
            .then((res) => {
                setOpenHelpModal(false);
                dispatch(setIsPopupSubmit(true));
                setTimeout(() => {
                    dispatch(setIsPopupSubmit(false));
                }, 3000);
                setReload((prev) => !prev);
            })
            .catch((err) => {
                dispatch(setIsPopupErr(true));
                setTimeout(() => {
                    dispatch(setIsPopupErr(false));
                }, 3000);
            })
            .finally(() => setLoading(false));
    };

    const downloadPdfFunc = () => {
        setLoading(true);
        downloadPdf(choosenId)
            .then((response) => {
                response.blob().then((blob) => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = `${subject}.pdf`;
                    alink.click();
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className="modal-wrapper"></div>
            <div className="help-modal">
                <div className="help-modal__wrap">
                    <div className="help-modal__title">
                        Submit quote to HD Mutual?
                    </div>
                    <div
                        className="help-modal__close"
                        onClick={() => {
                            setOpenHelpModal(false);
                        }}
                    >
                        ✖
                    </div>
                </div>
                <div style={{ margin: '32px 0' }}>
                    <div className="help-modal__congratulations">
                        Congratulations!
                    </div>
                    <div className="help-modal__subtitle">
                        You are just one step away from submitting your quote
                    </div>
                </div>
                <div className="help-modal__subject">
                    <img src={Image} style={{ margin: '0 12px 0 0 ' }} />
                    Subject: {subject}
                </div>
                <div className="help-modal__description description">
                    <div className="description__title">
                        Notes to underwriter
                    </div>
                    <textarea
                        className={`description__textarea ${
                            descriptionError ? 'red' : ''
                        }`}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Additional information, specific requests, etc. Please include age and type for construction, heating, plumbing, electrical, roof, etc. Any other information required for binding."
                        value={description}
                        maxLength={1000}
                    />
                    <div className="error">{descriptionError}</div>
                </div>
                <div>
                    <div className="help-modal__pdf pdf">
                        <div className="pdf__wrap">
                            <img src={PDFImage} className="pdf__image" />
                            <div>
                                <div>{subject}.pdf</div>
                            </div>
                        </div>
                        <img
                            src={DownloadImage}
                            className="pdf__download-image"
                            onClick={downloadPdfFunc}
                        />
                    </div>
                </div>
                <div className="help-modal__buttons buttons">
                    <div
                        className="buttons__cancel btn"
                        onClick={() => setOpenHelpModal(false)}
                    >
                        Cancel
                    </div>
                    <div
                        className="buttons__submit btn"
                        onClick={submitTicketFunc}
                    >
                        Submit
                    </div>
                </div>
            </div>
        </>
    );
};
