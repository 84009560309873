import React, { useState } from 'react';
import { CheckboxContainer } from '../CustomFields/CheckboxContainer';
import { CustomSelection } from '../CustomFields/CustomSelection';
import { InputField } from '../CustomFields/InputField';
import { TotalPremium } from '../TotalPremium/TotalPremium';
import { onInputChange, fillFormAction } from '../../helpers/formUtils';
import './steps.scss';
import { MultiSelect } from '../CustomFields/MultiSelect';
import { CalcStudent } from '../../helpers/AdditionalFunctions/calcFunctions/CalcStudent';
import { useEffect } from 'react';
import { validateWithConstStudent } from '../../helpers/AdditionalFunctions/validate/validateWithConstStudent';
import { setIsChanged } from '../../redux/quote/quote';
import { useDispatch } from 'react-redux';

export const SecondStepStudent = ({
    quotePackage,
    formData,
    setFormData,
    totalPremium,
    setTotalPremium,
    validation,
    additionalCoverages,
    setAdditionalCoverages,
    setAddCovError,
    addCovError,
    step,
    fillFormFinished,
}) => {
    const [tooltip, setTooltip] = useState(false);
    const [checkCrime, setCheckCrime] = useState(false);
    const dispatch = useDispatch();
    const [options, setOptions] = useState([
        { id: '0', title: ' Water damage deductible', type: 'checkbox' },

        {
            id: '1',
            title: ' Stated Amount - Waiver of Coinsurance  Endorsement',
            type: 'checkbox',
        },
        { id: '2', title: ' Equipment Breakdown', type: 'checkbox' },
        { id: '3', title: ' Flood', type: 'checkbox' },
    ]);

    useEffect(() => {
        if (validation) {
            const res = CalcStudent(formData, validation, additionalCoverages);
            setTotalPremium(res);
        }
    }, [formData, additionalCoverages, validation]);

    const currencyMask = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        // value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e.target.value;
    };

    useEffect(() => {
        if (!checkCrime) {
            const data = [...additionalCoverages];
            if (additionalCoverages.find((elem) => elem === 6)) {
                let index = additionalCoverages.findIndex((elem) => elem === 6);
                data.splice(index, 1);

                setAdditionalCoverages(data);
            }
            if (additionalCoverages.find((elem) => elem === 7)) {
                let index = additionalCoverages.findIndex((elem) => elem === 7);
                data.splice(index, 1);
                setAdditionalCoverages(data);
            }
        }
    }, [checkCrime]);

    const handalSelect = (value) => {
        if ((value === 6 || value === 7) && checkCrime) {
            if (value === 6) {
                if (additionalCoverages.find((elem) => elem === 7)) {
                    const data = [...additionalCoverages];
                    const res = data.filter((elem) => elem !== 7);
                    res.push(6);
                    setAdditionalCoverages(res);
                } else {
                    let data = [...additionalCoverages];
                    data.push(value);
                    setAdditionalCoverages(data);
                }
            }
            if (value === 7) {
                if (additionalCoverages.find((elem) => elem === 6)) {
                    const data = [...additionalCoverages];
                    const res = data.filter((elem) => elem !== 6);
                    res.push(7);
                    setAdditionalCoverages(res);
                } else {
                    let data = [...additionalCoverages];
                    data.push(value);
                    setAdditionalCoverages(data);
                }
            }
        } else {
            if (additionalCoverages.find((elem) => elem + '' === value)) {
                const data = [...additionalCoverages];
                const res = data.filter((elem) => elem !== value);
                setAdditionalCoverages(res);
            } else {
                let data = [...additionalCoverages];
                data.push(value);
                setAdditionalCoverages(data);
            }
        }
    };

    useEffect(() => {
        if (
            additionalCoverages.includes('3') &&
            formData.flood.value.id === 1
        ) {
            setAddCovError(`Please check flood eligibility`);
        } else if (additionalCoverages.includes('2')) {
            if (
                formData.rental_type.value.id === 0 &&
                (formData.number_of_students.value.id === 0 ||
                    formData.number_of_students.value.id === 1)
            ) {
                const landlord_contents =
                    formData?.landlord_contents?.value?.split(',')?.join('') ||
                    0;
                const additional_building =
                    formData?.additional_building?.value
                        ?.split(',')
                        ?.join('') || 0;
                const gross_rental_income =
                    formData?.gross_rental_income?.value
                        ?.split(',')
                        ?.join('') || 0;
                const dwelling =
                    formData?.dwelling?.value?.split(',')?.join('') || 0;
                const prop_limits =
                    +landlord_contents +
                    +additional_building +
                    +gross_rental_income +
                    +dwelling;
                if (prop_limits > validation?.EB_Prem_limit4) {
                    setAddCovError(
                        `Property limits should not exceed $${validation?.EB_Prem_limit4}`
                    );
                } else {
                    setAddCovError('');
                }
            }

            if (
                formData.rental_type.value.id === 1 &&
                (formData.number_of_students.value.id === 0 ||
                    formData.number_of_students.value.id === 1)
            ) {
                const landlord_contents =
                    formData?.landlord_contents?.value?.split(',')?.join('') ||
                    0;
                const improvements_betterments =
                    formData?.improvements_betterments?.value
                        ?.split(',')
                        ?.join('') || 0;
                const gross_rental_income =
                    formData?.gross_rental_income?.value
                        ?.split(',')
                        ?.join('') || 0;
                const prop_limits =
                    +landlord_contents +
                    +improvements_betterments +
                    +gross_rental_income;
                if (prop_limits >= validation.EB_Prem_limit4) {
                    setAddCovError(
                        `Property limits should not exceed $${validation.EB_Prem_limit4}`
                    );
                } else {
                    setAddCovError('');
                }
            }
        } else {
            setAddCovError('');
        }
    }, [additionalCoverages, formData]);

    // call validateWithConstStudent function when fields are updated
    useEffect(() => {
        if (fillFormFinished) {
            validateWithConstStudent(
                step,
                formData,
                setFormData,
                fillFormAction,
                validation
            );
        }
    }, [
        formData.rental_type.value.id,
        formData.dwelling,
        formData.landlord_contents,
        formData.gross_rental_income,
        fillFormFinished,
    ]);

    return (
        <>
            <div className="step-wrapper">
                <div className="step-wrapper__row">
                    <div
                        className="step-wrapper__select-container"
                        style={{ width: '206px' }}
                    >
                        <CustomSelection
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    Number of students:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            options={[
                                { id: 0, title: '1-5' },
                                { id: 1, title: '6-10' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'number_of_students',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.number_of_students.value.title}
                            errorText={
                                formData.number_of_students.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div
                        className="step-wrapper__select-container"
                        style={{ width: '206px' }}
                    >
                        <CustomSelection
                            name="Deductible"
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    Deductible:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            options={[
                                { id: 0, title: '$1,000' },
                                { id: 1, title: '$2,500' },
                                { id: 2, title: '$5,000' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'deductible',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.deductible.value.title}
                            errorText={
                                formData.deductible.hasError &&
                                'This field is required'
                            }
                        />
                    </div>

                    <div
                        className="step-wrapper__select-container"
                        style={{ width: '206px' }}
                    >
                        <CustomSelection
                            type="text"
                            title={
                                <div>
                                    Rental type:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            placeholder={'Select answer'}
                            arrowImage
                            options={[
                                { id: 0, title: 'Dwelling' },
                                { id: 1, title: 'Condominium' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'rental_type',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.rental_type.value.title}
                            errorText={
                                formData.rental_type.hasError &&
                                'This field is required'
                            }
                        />
                    </div>

                    <div
                        className="step-wrapper__select-container"
                        style={
                            formData.sewer_back_up.disabled ||
                                formData.number_of_students.value.id === 0
                                ? { width: '206px', visibility: 'hidden' }
                                : { width: '206px' }
                        }
                    >
                        <CustomSelection
                            disabled={
                                formData.sewer_back_up.disabled ||
                                    formData.number_of_students.value.id === 0
                                    ? true
                                    : false
                            }
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    Sewer Back-Up:
                                    {formData.number_of_students.value.title ===
                                        '6-10' &&
                                        formData.rental_type.value.title && (
                                            <span className="mandatory">*</span>
                                        )}
                                </div>
                            }
                            options={[
                                { id: 0, title: '$10 000' },
                                { id: 1, title: '$25 000' },
                                { id: 2, title: '$50 000' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'sewer_back_up',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.sewer_back_up.value.title}
                            errorText={
                                formData.sewer_back_up.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>

                <div
                    className={`step-wrapper__subtitle ${formData.improvements_betterments.disabled
                        ? 'disabled'
                        : ''
                        }`}
                    style={
                        formData.improvements_betterments.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    Condominium
                </div>

                <div
                    className="step-wrapper__row"
                    style={
                        formData.improvements_betterments.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Improvemets & Betterments:{' '}
                                    {formData.rental_type.value.id === 1 &&
                                        formData.number_of_students.value
                                            .title && (
                                            <span className="mandatory">*</span>
                                        )}
                                </div>
                            }
                            disabled={
                                formData.improvements_betterments.disabled
                                    ? true
                                    : false
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.improvements_betterments.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.improvements_betterments.hasError &&
                                formData.improvements_betterments.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'improvements_betterments',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Loss Assessment:{' '}
                                    {formData.rental_type.value.id === 1 &&
                                        formData.number_of_students.value
                                            .title && (
                                            <span className="mandatory">*</span>
                                        )}
                                </div>
                            }
                            disabled={
                                formData.loss_assessment.disabled ? true : false
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.loss_assessment.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.loss_assessment.hasError &&
                                formData.loss_assessment.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'loss_assessment',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={
                                formData.unit_owner_protection.disabled
                                    ? true
                                    : false
                            }
                            title={
                                <div>
                                    Unit Owner Protection:{' '}
                                    {formData.rental_type.value.id === 1 &&
                                        formData.number_of_students.value
                                            .title && (
                                            <span className="mandatory">*</span>
                                        )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.unit_owner_protection.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.unit_owner_protection.hasError &&
                                formData.unit_owner_protection.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'unit_owner_protection',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                </div>
                <div
                    className={`step-wrapper__subtitle ${formData.additional_building.disabled ? 'disabled' : ''
                        }`}
                    style={
                        formData.additional_building.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    Dwelling
                </div>
                <div
                    className="step-wrapper__row"
                    style={
                        formData.additional_building.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={formData.dwelling.disabled ? true : false}
                            title={
                                <div>
                                    Dwelling:{' '}
                                    {formData.rental_type.value.id === 0 &&
                                        formData.number_of_students.value
                                            .title && (
                                            <span className="mandatory">*</span>
                                        )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.dwelling.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.dwelling.hasError &&
                                formData.dwelling.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'dwelling',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={
                                formData.additional_building.disabled
                                    ? true
                                    : false
                            }
                            title={
                                <div>
                                    Additional Building:{' '}
                                    {/* {formData.rental_type.value.id === 0 &&
                                        formData.number_of_students.value
                                            .title && (
                                            <span className="mandatory">*</span>
                                        )} */}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.additional_building.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.additional_building.hasError &&
                                formData.additional_building.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'additional_building',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    
                    <div className="step-wrapper__select-container"></div>
                </div>
                <div className="step-wrapper__subtitle">Additional Limits</div>
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Landlord Contents:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.landlord_contents.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.landlord_contents.hasError &&
                                formData.landlord_contents.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'landlord_contents',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Gross Rental Income:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.gross_rental_income.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.gross_rental_income.hasError &&
                                formData.gross_rental_income.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'gross_rental_income',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <CustomSelection
                            title={
                                <div>
                                    Liability Limit:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            options={[
                                { id: 0, title: '$2,000,000' },
                                { id: 1, title: '$3,000,000' },
                                { id: 2, title: '$4,000,000' },
                                { id: 3, title: '$5,000,000' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'liability_limit',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.liability_limit.value.title}
                            errorText={
                                formData.liability_limit.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>
                <div
                    className={`step-wrapper__subtitle ${formData.location_three_or_more_years.disabled
                        ? 'disabled'
                        : ''
                        }`}
                    style={
                        formData.location_three_or_more_years.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    Discounts
                </div>
                <div
                    className="step-wrapper__column-container"
                    style={
                        formData.location_three_or_more_years.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    <div className="step-wrapper__column">
                        <CheckboxContainer
                            disabled={
                                formData.location_three_or_more_years.disabled
                                    ? true
                                    : false
                            }
                            title={
                                'Has the client insured this location for 3 or more years?'
                            }
                            checked={
                                formData.location_three_or_more_years.value
                            }
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        location_three_or_more_years: {
                                            value: !formData
                                                .location_three_or_more_years
                                                .value,
                                        },
                                    })
                                );
                            }}
                        />

                        <CheckboxContainer
                            disabled={
                                formData.building_past_three_years.disabled
                                    ? true
                                    : false
                            }
                            title={
                                'Has the building been claims free for the past 3 years?'
                            }
                            checked={formData.building_past_three_years.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        building_past_three_years: {
                                            value: !formData
                                                .building_past_three_years
                                                .value,
                                        },
                                    })
                                );
                            }}
                        />

                        <CheckboxContainer
                            disabled={
                                formData.dwelling_age.disabled ? true : false
                            }
                            title={
                                'Dwelling age is 15 years or less (Single family/duplex)'
                            }
                            checked={formData.dwelling_age.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        dwelling_age: {
                                            value: !formData.dwelling_age.value,
                                        },
                                    })
                                );
                            }}
                        />
                    </div>
                    <div className="step-wrapper__column">
                        <div className="step-wrapper__plus-wrap">
                            <CheckboxContainer
                                disabled={
                                    formData.certified_rental_building.disabled
                                        ? true
                                        : false
                                }
                                title={
                                    'Certified Rental Building Program certification?'
                                }
                                checked={
                                    formData.certified_rental_building.value
                                }
                                onChange={() => {
                                    dispatch(setIsChanged(true));
                                    setFormData(
                                        fillFormAction({
                                            ...formData,
                                            certified_rental_building: {
                                                value: !formData
                                                    .certified_rental_building
                                                    .value,
                                            },
                                        })
                                    );
                                }}
                            />
                        </div>

                        <CheckboxContainer
                            disabled={
                                formData.customer_more_three_year.disabled
                                    ? true
                                    : false
                            }
                            title={
                                'Have you been a customer with HD Mutual for more than 3 years?'
                            }
                            checked={formData.customer_more_three_year.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        customer_more_three_year: {
                                            value: !formData
                                                .customer_more_three_year.value,
                                        },
                                    })
                                );
                            }}
                        />

                        <div></div>
                    </div>
                </div>
                <div
                    className="step-wrapper__subtitle"
                    style={{ padding: '0 0 20px 0' }}
                >
                    Additional Coverages
                </div>

                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <MultiSelect
                            arrowImage
                            placeholder={'Select multiple answers'}
                            background
                            title={
                                <div>
                                    Additional Coverages <br /> (if required):
                                </div>
                            }
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                handalSelect(value);
                            }}
                            formData={additionalCoverages}
                            checkCrime={checkCrime}
                            setCheckCrime={setCheckCrime}
                            options={options}
                            value={additionalCoverages
                                .map((elem) => `${options[+elem].title},`)
                                .join('')}
                            errorText={addCovError}
                        />
                    </div>

                    <div className="step-wrapper__select-container">
                        <InputField
                            // disabled={
                            //     formData.other_policy_with_halwell.disabled
                            //         ? true
                            //         : false
                            // }
                            value={formData.other_policy_with_halwell.value}
                            title="Do you have any other policy with Halwell?"
                            placeholder="Please enter policy number"
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'other_policy_with_halwell',
                                    e.target.value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                            errorText={
                                formData.other_policy_with_halwell.hasError &&
                                formData.other_policy_with_halwell.error
                            }
                        />
                    </div>
                    <div
                        className="step-wrapper__select-container"
                        style={{ width: '230px' }}
                    >

                    </div>

                </div>
                <div className="step-wrapper__row">
                    <div
                        className="step-wrapper__select-container"
                        style={additionalCoverages.includes('3') ? { width: '230px' } : { width: '230px', display: 'none' }}
                    >
                        <CustomSelection
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Flood (Is this risk eligible{' '}
                                        <span className="mandatory">*</span>
                                        <br /> for coverage):{' '}
                                        <span
                                            className="question-mark"
                                            onMouseOver={() => setTooltip(true)}
                                            onMouseLeave={() =>
                                                setTooltip(false)
                                            }
                                        >
                                            ?
                                        </span>
                                    </div>{' '}
                                </div>
                            }
                            options={[
                                { id: 0, title: 'Yes' },
                                { id: 1, title: 'No' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'flood',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.flood.value.title}
                            errorText={
                                formData.flood.hasError && formData.flood.error
                            }
                        />
                        {tooltip && (
                            <div className="tooltip">
                                <ul>
                                    Ineligible if:
                                    <li>Past flood claims at the location</li>
                                    <li>Location is in a known flood plain </li>
                                    <li>
                                        Location is within 500m of any body of
                                        water{' '}
                                    </li>
                                    <li>
                                        Location is located below grade level{' '}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="step-wrapper">
                    <TotalPremium
                        total={totalPremium}
                        addCovError={addCovError}
                    />
                </div>
            </div>
        </>
    );
};
