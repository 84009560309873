import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import favicon from './assets/images/browser-favicon.png';


const root = ReactDOM.createRoot(document.getElementById('root'));
const REACT_APP_ENV = process.env.REACT_APP_ENV;

// Create a new link element for the favicon
const newLinkElement = document.createElement('link');
newLinkElement.rel = 'icon';
newLinkElement.href = favicon;
document.head.appendChild(newLinkElement);

// Check the value of process.env.REACT_APP_ENV and set the document title accordingly
if (REACT_APP_ENV === 'test' || REACT_APP_ENV === 'qa') {
    document.title = 'HD Connect - ' + REACT_APP_ENV;
  } else if (REACT_APP_ENV === 'prod') {
    document.title = 'HD Connect';
  } else {
    // Optional: Handle other cases or set a default title
    document.title = 'HD Connect';
  }

  console.log('REACT_APP_ENV: ', REACT_APP_ENV);

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
    /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

