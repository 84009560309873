export const CalcStudent = (formData, validation, additionalCoverages) => {
    let totalPremium = 0;

    // set constants from the form data provided in the function call
    const number_of_students = formData?.number_of_students?.value?.id;
    const rental_type = formData?.rental_type?.value?.id;
    const sewer_back_up = formData?.sewer_back_up?.value?.id;
    const landlord_contents =
        formData?.landlord_contents?.value?.split(',')?.join('') || 0;
    const improvements_betterments =
        formData?.improvements_betterments?.value?.split(',')?.join('') || 0;
    const unit_owner_protection =
        formData?.unit_owner_protection?.value?.split(',')?.join('') || 0;
    const loss_assessment =
        formData?.loss_assessment?.value?.split(',')?.join('') || 0;
    const flood = formData?.flood?.value?.id;
    const gross_rental_income =
        formData?.gross_rental_income?.value?.split(',')?.join('') || 0;
    const liability_limit = formData?.liability_limit?.value?.title || 0;
    const deductible = formData?.deductible?.value?.title;
    const equipment_breakdown = additionalCoverages?.includes('2');
    const dwelling = formData?.dwelling?.value?.split(',')?.join('') || 0;
    const additional_building =
        formData?.additional_building?.value?.split(',')?.join('') || 0;
    const location_three_or_more_years =
        formData?.location_three_or_more_years.value;
    const building_past_three_years =
        formData?.building_past_three_years?.value;
    const other_policy_with_halwell =
        formData?.other_policy_with_halwell?.value;
    const customer_more_three_year = formData?.customer_more_three_year?.value;
    const dwelling_age = formData?.dwelling_age?.value;
    const certified_rental_building =
        formData?.certified_rental_building?.value;

    let r = 0;
    if (number_of_students === 0) {
        r = validation?.r_property_limits_1_5;
    }
    if (number_of_students === 1) {
        r = validation?.r_property_limits_6_10;
    }

    if (
        (number_of_students === 0 || number_of_students === 1) &&
        rental_type === 1
    ) {
        let LC_prem = 0;
        if (landlord_contents) {
            LC_prem =
                validation?.LanConts_base_premium +
                (landlord_contents - validation?.LanConts_base_limit) *
                    validation?.r_increased_property_limits;
        } else {
            LC_prem = validation?.LanConts_base_premium;
        }

        let IB_prem = 0;
        if (improvements_betterments) {
            if (improvements_betterments === validation?.IB_base_limit) {
                IB_prem = 0;
            } else {
                IB_prem =
                    (improvements_betterments - validation?.IB_base_limit) *
                    validation?.r_increased_property_limits;
            }
        }
        let UOAP_prem = 0;
        if (unit_owner_protection) {
            if (unit_owner_protection === validation?.UOAP_base_limit) {
                UOAP_prem = 0;
            } else {
                UOAP_prem =
                    (unit_owner_protection - validation?.UOAP_base_limit) *
                    validation?.r_increased_property_limits;
            }
        }

        let LA_prem = 0;
        if (loss_assessment) {
            if (loss_assessment === validation?.LA_base_limit) {
                LA_prem = 0;
            } else {
                LA_prem =
                    (loss_assessment - validation?.LA_base_limit) *
                    validation?.r_increased_property_limits;
            }
        }
        let SB_prem = 0;
        if (number_of_students === 0) {
            SB_prem = validation?.SB_prem_student_condo;
        }
        if (number_of_students === 1) {
            if (sewer_back_up === 0) {
                SB_prem = validation?.SB_Prem_Studentcondo10000;
            }
            if (sewer_back_up === 1) {
                SB_prem = validation?.SB_Prem_Studentcondo25000;
            }
            if (sewer_back_up === 2) {
                SB_prem = validation?.SB_Prem_Studentcondo50000;
            }
        }
        let Flood_prem = 0;

        if (flood === 0 && additionalCoverages?.includes('3')) {
            Flood_prem = validation?.FL_prem_student_condo;
        } else {
            Flood_prem = 0;
        }
        let EQ_prem = validation?.EQ_prem;
        let GRI_prem = 0;
        if (gross_rental_income) {
            if (gross_rental_income === validation?.GRI_base_limit) {
                GRI_prem = 0;
            } else {
                GRI_prem =
                    (gross_rental_income - validation?.GRI_base_limit) *
                    validation?.r_increased_property_limits;
            }
        }
        let Liab_prem = 0;
        if (liability_limit) {
            if (number_of_students === 0) {
                if (liability_limit === '$2,000,000') {
                    Liab_prem = validation?.Liab_base_premium_condo_1_5;
                }
                if (liability_limit === '$3,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_1_5 +
                        validation?.Liab_ip1_condo_1_5;
                }
                if (liability_limit === '$4,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_1_5 +
                        validation?.Liab_ip2_condo_1_5;
                }
                if (liability_limit === '$5,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_1_5 +
                        validation?.Liab_ip3_condo_1_5;
                }
            }
            if (number_of_students === 1) {
                if (liability_limit === '$2,000,000') {
                    Liab_prem = validation?.Liab_base_premium_condo_6_10;
                }
                if (liability_limit === '$3,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_6_10 +
                        validation?.Liab_ip1_condo_6_10;
                }
                if (liability_limit === '$4,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_6_10 +
                        validation?.Liab_ip2_condo_6_10;
                }
                if (liability_limit === '$5,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_condo_6_10 +
                        validation?.Liab_ip3_condo_6_10;
                }
            }
        }

        let EB_prem = 0;
        if (!equipment_breakdown) {
            EB_prem = 0;
        } else {
            let Prop_limits =
                +landlord_contents +
                +improvements_betterments +
                +gross_rental_income;
            if (+Prop_limits <= validation?.EB_Prem_limit1) {
                const res = +Prop_limits * validation?.R_prem_limit1;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                +Prop_limits > validation?.EB_Prem_limit1 &&
                +Prop_limits <= validation?.EB_Prem_limit2
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (+Prop_limits - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit2 < +Prop_limits &&
                +Prop_limits <= validation?.EB_Prem_limit3
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (+Prop_limits - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit3 < +Prop_limits &&
                +Prop_limits <= validation?.EB_Prem_limit4
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (validation?.EB_Prem_limit3 - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3 +
                    (+Prop_limits - validation?.EB_Prem_limit3) *
                        validation?.R_prem_limit4;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
        }
        let ded_disc1 = 0;
        if (!deductible) {
            ded_disc1 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc1 = 0;
        }
        if (deductible === '$2,500') {
            ded_disc1 = validation?.Ded_disc_2500;
        }
        if (deductible === '$5,000') {
            ded_disc1 = validation?.Ded_disc_5000;
        }
        let ded_disc2 = 0;
        if (EB_prem === 0) {
            ded_disc2 = 0;
        }
        if (!deductible) {
            ded_disc2 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible1000;
        }
        if (deductible === '$2,500') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible2500;
        }
        if (deductible === '$5,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible5000;
        }
        let Ded_disc = ded_disc1 + ded_disc2;

        const total =
            LC_prem +
            IB_prem +
            UOAP_prem +
            LA_prem +
            SB_prem +
            Flood_prem +
            EQ_prem +
            GRI_prem +
            EB_prem +
            Liab_prem -
            Ded_disc;

        if (total < validation?.Total_premium_min_studcondo) {
            return (totalPremium = validation?.Total_premium_min_studcondo);
        } else {
            return (totalPremium = total);
        }
    }
    if (
        (number_of_students === 0 || number_of_students === 1) &&
        rental_type === 0
    ) {
        let Dwelling_prem = 0;
        if (dwelling) {
            Dwelling_prem = r * dwelling;
        }

        let AdditionalBuilding_prem = 0;
        if (additional_building) {
            AdditionalBuilding_prem = r * additional_building;
        }

        let LC_prem = 0;
        if (landlord_contents) {
            if (landlord_contents === validation?.LanConts_base_limit) {
                LC_prem = 0;
            } else {
                LC_prem =
                    (landlord_contents - validation?.LanConts_base_limit) * r;
            }
        }

        let GRI_prem = 0;
        if (gross_rental_income) {
            if (
                gross_rental_income ===
                validation?.GRI_Dwelling_validation * dwelling
            ) {
                GRI_prem = 0;
            }
            if (
                gross_rental_income >
                validation?.GRI_Dwelling_validation * dwelling
            ) {
                GRI_prem =
                    (gross_rental_income -
                        validation?.GRI_Dwelling_validation * dwelling) *
                    r;
            }
        }

        let Liab_prem = 0;
        if (liability_limit) {
            if (number_of_students === 0) {
                if (liability_limit === '$2,000,000') {
                    Liab_prem = validation?.Liab_base_premium_dwelling_1_5;
                }
                if (liability_limit === '$3,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_1_5 +
                        validation?.Liab_ip1_dwelling_1_5;
                }
                if (liability_limit === '$4,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_1_5 +
                        validation?.Liab_ip2_dwelling_1_5;
                }
                if (liability_limit === '$5,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_1_5 +
                        validation?.Liab_ip3_dwelling_1_5;
                }
            }
            if (number_of_students === 1) {
                if (liability_limit === '$2,000,000') {
                    Liab_prem = validation?.Liab_base_premium_dwelling_6_10;
                }
                if (liability_limit === '$3,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_6_10 +
                        validation?.Liab_ip1_dwelling_6_10;
                }
                if (liability_limit === '$4,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_6_10 +
                        validation?.Liab_ip2_dwelling_6_10;
                }
                if (liability_limit === '$5,000,000') {
                    Liab_prem =
                        validation?.Liab_base_premium_dwelling_6_10 +
                        validation?.Liab_ip3_dwelling_6_10;
                }
            }
        }

        let SB_prem = 0;

        if (number_of_students === 1) {
            if (sewer_back_up === 0) {
                SB_prem = validation?.SB_Prem_Studentdwelling10000;
            }
            if (sewer_back_up === 1) {
                SB_prem = validation?.SB_Prem_Studentdwelling25000;
            }
            if (sewer_back_up === 2) {
                SB_prem = validation?.SB_Prem_Studentdwelling50000;
            }
        }

        let EB_prem = 0;
        if (!equipment_breakdown) {
            EB_prem = 0;
        } else {
            let Prop_limits =
                +dwelling +
                +additional_building +
                +landlord_contents +
                +gross_rental_income;
            if (+Prop_limits <= validation?.EB_Prem_limit1) {
                const res = +Prop_limits * validation?.R_prem_limit1;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                +Prop_limits > validation?.EB_Prem_limit1 &&
                +Prop_limits <= validation?.EB_Prem_limit2
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (+Prop_limits - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit2 < +Prop_limits &&
                +Prop_limits <= validation?.EB_Prem_limit3
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (+Prop_limits - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit3 < +Prop_limits &&
                +Prop_limits <= validation?.EB_Prem_limit4
            ) {
                const res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (validation?.EB_Prem_limit3 - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3 +
                    (+Prop_limits - validation?.EB_Prem_limit3) *
                        validation?.R_prem_limit4;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
        }

        let res = 0;
        

        if (number_of_students === 0) {
            res = Dwelling_prem + AdditionalBuilding_prem + LC_prem + GRI_prem;
        }
        if (number_of_students === 1) {
            res = Dwelling_prem + AdditionalBuilding_prem + LC_prem + GRI_prem;
        }

        /* 
        ==================================================
        This is where deductible discounts are calculated
        ==================================================
        */

        let ded_disc1 = 0;
        if (!deductible) {
            ded_disc1 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc1 = 0;
        }
        if (deductible === '$2,500') {
            ded_disc1 = res * validation?.Ded_Disc_rate2500;
        }
        if (deductible === '$5,000') {
            ded_disc1 = res * validation?.Ded_Disc_rate5000;
        }
        let ded_disc2 = 0;
        if (EB_prem === 0) {
            ded_disc2 = 0;
        }
        if (!deductible) {
            ded_disc2 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible1000;
        }
        if (deductible === '$2,500') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible2500;
        }
        if (deductible === '$5,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible5000;
        }
        let Ded_disc = ded_disc1 + ded_disc2;

        /* 
        ==================================================
        Calculate the discounts
        ==================================================
        */

        let total_premium = Dwelling_prem + AdditionalBuilding_prem + LC_prem + GRI_prem + SB_prem;

        // Subtract deductible discount from total premium
        total_premium -= Ded_disc;
        
        let Discount_Claims_free = 0;
        if (location_three_or_more_years && building_past_three_years) {
            Discount_Claims_free = total_premium * validation?.Discount_Claims_free_const;
        }

        let Discount_Multipolicy = 0;
        if (other_policy_with_halwell) {
            Discount_Multipolicy = total_premium * validation?.Discount_Multipolicy_const;
        }

        let Discount_Loyalty = 0;
        if (customer_more_three_year) {
            Discount_Loyalty = total_premium * validation?.Discount_loyalty_const;
        }

        let Discount_Dwelling15 = 0;
        const res1 = Discount_Claims_free + Discount_Multipolicy + Discount_Loyalty;
        const res2 = validation?.Discount_limit * total_premium;
        if (dwelling_age && res1 < res2) {
            Discount_Dwelling15 = total_premium * validation?.Discount_Dwelling15_const;
        }

        let Discount_certified_rental = 0;
        const res3 = Discount_Claims_free + Discount_Multipolicy + Discount_Loyalty + Discount_Dwelling15;
        const res4 = validation?.Discount_limit * total_premium;
        if (certified_rental_building && res3 < res4) {
            Discount_certified_rental = total_premium * validation?.Discount_certified_rental_program;
        }
        


        /* 
        ==================================================
        Calculate the total premium
        ==================================================
        */

        let total = 0;

        let Flood_prem = 0;

        if (flood === 0 && additionalCoverages?.includes('3')) {
            Flood_prem = validation?.FL_prem_student_dwelling;
        } else {
            Flood_prem = 0;
        }

        if (number_of_students === 0) {
            total =
                Dwelling_prem +
                AdditionalBuilding_prem +
                LC_prem +
                GRI_prem +
                Liab_prem +
                EB_prem -
                Discount_Claims_free -
                Discount_Multipolicy -
                Discount_Loyalty -
                Discount_Dwelling15 -
                Discount_certified_rental -
                Ded_disc +
                Flood_prem;
        }
        if (number_of_students === 1) {
            total =
                Dwelling_prem +
                AdditionalBuilding_prem +
                LC_prem +
                GRI_prem +
                Liab_prem +
                SB_prem +
                EB_prem -
                Discount_Claims_free -
                Discount_Multipolicy -
                Discount_Loyalty -
                Discount_Dwelling15 -
                Discount_certified_rental -
                Ded_disc +
                Flood_prem;
        }

        if (total < validation?.Total_premium_min_studdwelling) {
            return (totalPremium = validation?.Total_premium_min_studdwelling);
        } else {
            return (totalPremium = total);
        }
    }
    return totalPremium;
};
