import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import LoginImage from '../../assets/images/login-image.png';
import { InputField } from '../../components/CustomFields/InputField';
import userEmailImage from '../../assets/images/useEmailImage.png';
import passwordImage from '../../assets/images/passwordImage.png';
import Logo from '../../assets/images/Logo.png';
import { logIn } from '../../redux/auth/auth';
import {
    onInputChange,
    onFocusOut,
    formsReducer,
    updateFormAction,
    validateForm,
    fillFormAction,
} from '../../helpers/formUtils';
import { login } from '../../crud/auth';
import './login.scss';
import { useDispatch } from 'react-redux';
import { Loader } from '../../components/Loader/Loader';
import axios from 'axios';

const initialState = {
    email: { value: '', touched: '', hasError: '', error: '' },
    password: { value: '', touched: '', hasError: '', error: '' },
    isFormValid: false,
};

export const Login = () => {
    const [formData, setFormData] = useReducer(formsReducer, initialState);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const location = useLocation();

    const errorMessage = "Your email address or brokerage do not have access to online quoting.";

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const errorMsg = params.get('error');
        if (errorMsg) {
            setError(true);  // Set error to true if there's an error message
        }
    }, [location.search]);

    // Define the handleLogin function
    const handleLogin = () => {
        const redirectUri = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}auth/zendesk/oauth/callback`);
        const zendeskOAuthUrl = `https://halwell.zendesk.com/oauth/authorizations/new?response_type=code&client_id=halwell-connect&redirect_uri=${redirectUri}&scope=read%20write`;
        // Use window.location.replace to navigate to the Zendesk OAuth URL
        window.location.replace(zendeskOAuthUrl);
    };
    
    

    return (
        <>  
            <div className="login">
                <Loader loading={loading} />
                <div className="login__wrap">
                    <div className="login__top-wrap">
                        <img src={Logo} className="login__logo" />
                        <a
                            className="login__back-btn"
                            href="https://www.halwellmutual.com/?__cf_chl_tk=mXdikhW3BbTUINPaE0Momf9FOYM0.iKyq6gA_z7FDbU-1672665031-0-gaNycGzNCJE"
                            target="_blank"
                        >
                            Back to website
                        </a>
                    </div>
                    <div className="login__mainblock">
                        <img src={LoginImage} className="login__image" />
                        <div className="login__title">
                            Log into HD Mutual
                        </div>
                        {/* The Login with Zendesk button */}
                        <button className="login__btn" onClick={handleLogin}>Login with Zendesk</button>
                        {error && (
                            <div className="login__error-block">
                                <i className="fas fa-exclamation-circle error-icon"></i>
                                Your email address or brokerage does not have access to this tool.
                            </div>
                        )}
                    </div>
                    <div></div>
                </div>
                <Footer />
            </div>
        </>
    );
};