import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fillFormAction } from '../../helpers/formUtils';
import './steps.scss';
import { setIsChanged } from '../../redux/quote/quote';
import { getTableData } from '../../crud/quote';

export const FourthStep = ({
    tableData,
    setFormData4,
    formData4,
    loading,
    setLoading,
    currentQuote,
    setTableData,
    afterSave,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (afterSave) return;
        setLoading(true);
        getTableData(currentQuote)
            .then((res) => setTableData(res.data))
            .finally(() => setLoading(false));
    }, []);

    const getDay = (date) => {
        if (!date) {
            return '';
        } else {
            const day = date?.getDay();
            if (day === 0) {
                return 'Sunday';
            }
            if (day === 1) {
                return 'Monday';
            }
            if (day === 2) {
                return 'Tuesday';
            }
            if (day === 3) {
                return 'Wednesday';
            }
            if (day === 4) {
                return 'Thursday';
            }
            if (day === 5) {
                return 'Friday';
            }
            if (day === 6) {
                return 'Saturday';
            }
        }
    };

    const getMonth = (date) => {
        if (!date) {
            return '';
        } else {
            const month = date?.getMonth();
            if (month === 0) {
                return 'January';
            }
            if (month === 1) {
                return 'February';
            }
            if (month === 2) {
                return 'March';
            }
            if (month === 3) {
                return 'April';
            }
            if (month === 4) {
                return 'May';
            }
            if (month === 5) {
                return 'June';
            }
            if (month === 6) {
                return 'July';
            }
            if (month === 7) {
                return 'August';
            }
            if (month === 8) {
                return 'September';
            }
            if (month === 9) {
                return 'October';
            }
            if (month === 10) {
                return 'November';
            }
            if (month === 11) {
                return 'December';
            }
        }
    };

    return (
        !loading && (
            <div className="step-wrapper">
                <div className="step-wrapper top-block">
                    <div className="top-block__title">
                        HD MUTUAL INSURANCE COMPANY
                    </div>
                    <div className="top-block__subtitle">
                        {tableData?.package}
                    </div>
                    <div className="top-block__info info">
                        <div className="info__column" style={{ width: '65%' }}>
                            <div className="info__item">
                                <div className="info__item-title">
                                    Quote Number:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.quote_number}
                                </div>
                            </div>
                            <div className="info__item">
                                <div className="info__item-title">Prepared by:</div>
                                <div className="info__item-name">
                                    {tableData?.broker}
                                </div>
                            </div>
                            <div className="info__item">
                                <div className="info__item-title">
                                    Broker:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.broker_location}
                                </div>
                            </div>
                            <div className="info__item">
                                <div className="info__item-title">
                                    Date Prepared:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.prepared}
                                </div>
                            </div>{' '}
                            <div className="info__item">
                                <div className="info__item-title">
                                    Quote Expiry:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.expired}
                                </div>
                            </div>{' '}
                            <div className="info__item">
                                <div className="info__item-title">
                                    Insured's Name:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.name_of_insured}
                                </div>
                            </div>{' '}
                            <div className="info__item">
                                <div className="info__item-title">
                                    Mailing Address:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.mailing_address}
                                </div>
                            </div>{' '}
                            <div className="info__item">
                                <div className="info__item-title">Package:</div>
                                <div className="info__item-name">
                                    {tableData?.package}
                                </div>
                            </div>{' '}
                            <div className="info__item">
                                <div className="info__item-title">
                                    Description of Operations:
                                </div>
                                <div className="info__item-name">
                                    {tableData?.operation_description}
                                </div>
                            </div>
                        </div>
                        <div className="info__column" style={{ width: '34%' }}>
                            <div className="info__item signature">
                                <div className="info__item-title">
                                    Effective Date:
                                </div>
                                <div className="info__item-name">
                                    {getDay(new Date(tableData.effective_date))}
                                    ,{' '}
                                    {getMonth(
                                        new Date(tableData.effective_date)
                                    )}{' '}
                                    {new Date(
                                        tableData.effective_date
                                    ).getDate()}
                                    ,{' '}
                                    {new Date(
                                        tableData.effective_date
                                    ).getFullYear()}
                                </div>
                            </div>
                            <div className="info__item signature">
                                <div className="info__item-title signature">
                                    Broker Signature:
                                </div>
                                <div className="info__item-name signature"></div>
                            </div>
                            <div className="info__item signature">
                                <div className="info__item-title signature">
                                    Client Signature:
                                </div>
                                <div className="info__item-name signature"></div>
                            </div>{' '}
                        </div>
                    </div>
                </div>
                <div className="step-wrapper table-block">
                    <div className="table-block__header header">
                        <div className="header__item">Item Type</div>
                        <div className="header__item">Coverage</div>
                        <div className="header__item">Deductible</div>
                        <div className="header__item">Co-Ins</div>
                        <div className="header__item">Limit</div>
                    </div>
                    {tableData?.data?.map((elem, index) => {
                        return (
                            <div
                                key={index}
                                className={`table-block__row row ${
                                    elem.item_type ? 'row-main' : ''
                                } `}
                            >
                                <div className={`row__item `}>
                                    {elem.item_type}
                                </div>
                                <div
                                    className={`row__item ${
                                        elem.item_type ? '' : 'border'
                                    }`}
                                >
                                    {elem.coverage}
                                </div>
                                <div
                                    className={`row__item ${
                                        elem.item_type ? '' : 'border'
                                    }`}
                                >
                                    {elem.deductible}
                                </div>
                                <div
                                    className={`row__item ${
                                        elem.item_type ? '' : 'border'
                                    }`}
                                >
                                    {elem.co_ins}
                                </div>
                                <div
                                    className={`row__item ${
                                        elem.item_type ? '' : 'border'
                                    }`}
                                >
                                    {elem.limit === 'Not Applicable' ||
                                    elem.limit === 'Not Eligible' ? (
                                        <div style={{ color: '#FF4444' }}>
                                            {elem.limit}
                                        </div>
                                    ) : (
                                        <>{elem.limit}</>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <div className="table-block__line"></div>
                    <div className="table-block__wrap">
                        <div>
                            <div className="table-block__min">
                                <div>Min. Retained Premium — </div>
                                <div style={{ padding: '0 0 0 15px' }}>
                                    {tableData.min_total_premium}
                                </div>
                            </div>
                            <div className="table-block__total">
                                <div>Total Premium — </div>
                                <div style={{ padding: '0 0 0 15px' }}>
                                    {tableData?.total_premium
                                        ?.toString()
                                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </div>
                            </div>
                        </div>
                        <div className="table-block__value">
                            Notes: If bound, HD Mutual to complete a Loss
                            Prevention Survey Minimum retained premium is $250
                        </div>
                    </div>
                    <div className="table-block__line"></div>

                    <div className="table-block__additiolan-notes">
                        <div className="table-block__custom-field-title">
                            Additional Notes:
                        </div>
                        <textarea
                            placeholder="Type your answer"
                            className="customInput__input"
                            maxLength={10000}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                setFormData4(
                                    fillFormAction({
                                        additional_notes: e.target.value,
                                    })
                                );
                            }}
                            value={formData4.additional_notes}
                        />
                    </div>
                </div>
            </div>
        )
    );
};
